@font-face {
    font-family: PublicPixel;
    src: url('./PublicPixel-z84yD.ttf');
}

.pong {
    display: grid;
    place-items: center;
    width: 100vw;
    height: 100vh;
}   

.pong canvas {
    width: 800px;
    height: 600px;
    font-family: 'PublicPixel', monospace;
    background-color: black;
}