@font-face {
	font-family: VT323;
	src: url('./assets/VT323-Regular.ttf');
}

@font-face {
	font-family: Source Code;
	src: url('./assets/SourceCodePro-VariableFont_wght.ttf');
}

* {
	padding: 0px;
	margin: 0px;
	font-family: 'Source Code', monospace;
	box-sizing: border-box;
}

html,
.web-content {
	overflow-x: hidden;
}

body {
	position: relative;
	background-color: #0c0c0c;
	scroll-behavior: smooth;
}

/**************
 * Navigation *
 **************/

.nav-bar {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 8vh;
	width: 100vw;
	z-index: 200;
}

.site-identity {
	display: flex;
	justify-content: left;
	align-items: center;
	text-decoration: none;
	flex: 50%;
}

.site-link {
	display: flex;
	justify-content: left;
	align-items: center;
	text-decoration: none;
}

.site-logo {
	width: auto;
	height: 3.8vh;
	min-height: 30px;
	max-height: 42px;
	margin: 2px 2px 2px 20px;
}

.nav-links {
	display: flex;
	justify-content: space-around;
	align-items: center;
	list-style-type: none;
	margin-right: 10px;
	flex: 50%;
	transition: opacity 0.5s ease;
}

.nav-links li a {
	font-weight: 600;
	font-size: 0.9rem;
	letter-spacing: 1.2px;
	color: #fff;
	border: solid 2px;
	border-color: #ffffff00;
	border-radius: 7px;
	text-decoration: none;
	text-transform: uppercase;
	padding: 3px 7px 3px 7px;
	margin: 2px;
}

.nav-links li a:hover {
	border: solid 2px;
	border-color: #fff;
	transition: border-color 0.3s ease;
}

.active {
	color: #000 !important;
	background-color: #fff;
	border-radius: 7px;
}

.active:hover {
	background-color: #000000;
	color: #fff !important;
	transition: color, background-color 0.3s ease !important;
}

.search-container {
	position: relative;
	transition: all 1s;
	width: 4vh;
	height: 4vh;
	margin-right: 10px;
	box-sizing: border-box;
	align-items: center;
}

.search {
	position: absolute;
	right: 0;
	width: 100%;
	height: 4vh;
	max-height: 42px;
	outline: 0;
	border: 0;
	display: none;
	font-size: 0.9rem;
	border-radius: 7px;
	top: 50%;
	transform: translateY(-50%);
	padding: 0 20px;
}

.search-icon {
	box-sizing: border-box;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	padding: 6px;
	width: 4vh;
	height: 4vh;
	max-width: 40px;
	max-height: 40px;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	font-size: 2rem;
	transition: all 1s;
}

.results {
	display: flex;
	width: 100%;
	display: none;
	margin-top: 5vh;
	flex-direction: column;
	background-color: #000;
}

.result {
	padding: 10px 20px;
	color: #fff;
	text-decoration: none;
	font-size: 0.9rem;
}

.result:hover {
	background-color: #1c1c1c;
}

.search-container:hover,
.search-container:valid {
	width: 300px;
	cursor: pointer;
	border: none;
}

.search-container:hover .search,
.search-container:valid .search {
	display: block;
}

.search-container:hover .results,
.search-container:valid .results {
	display: flex;
}

.search-container:hover .search-icon,
.search-container:valid .search-icon {
	color: #000;
}

.burger {
	display: none;
}

.burger div {
	width: 25px;
	height: 3px;
	background-color: #fff;
	margin: 5px;
	transition: all 0.3s ease;
}

.toggle .line1 {
	transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
	opacity: 0;
}

.toggle .line3 {
	transform: rotate(45deg) translate(-5px, -6px);
}

/**********
 * Header *
 **********/

header {
	position: relative;
	height: 100vh;
	width: 100vw;
}

.header-animation {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(#000, #0c0c0c);
}

.header-content {
	height: 92vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.header-title {
	color: #fff;
	font-size: calc(9vw + 2rem);
	font-family: 'VT323', monospace;
	text-align: center;
}

.header-title::after {
	content: '_';
	animation: blinking 0.8s steps(2, start) infinite;
}

.call-to-action {
	color: #fff;
	font-size: 3rem;
	text-decoration: none;
	text-transform: uppercase;
	position: absolute;
	bottom: 50px;
	text-shadow: #009f00 1px 1px 1px;
	cursor: pointer;
	animation: flashing 2s infinite;
}

/*******
* Page *
********/

.page {
	background: linear-gradient(#0c0c0c, #1c1c1c);
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	padding: 20px;
}

.page .page-section:nth-child(odd) {
	transform: translateX(80%);
}

.page .page-section:nth-child(even) {
	transform: translateX(-80%);
}

.page section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 80vw;
	min-height: 100vh;
	min-width: 330px;
	padding: 25vh 25px;
	max-width: 950px;
	opacity: 0;
	filter: blur(5px);
	transition: all 1s, filter 0.5s;
}

.page .show {
	transform: translateX(0) !important;
	filter: blur(0px);
	opacity: 1;
}

.page .page-section .sub-section {
	padding-left: 25px;
}

.page .page-section h4 {
	color: #fff;
	font-size: 1.3rem;
	margin: 10px 0;
}

.page .page-section p {
	padding-left: 25px;
	margin: 0px auto;
	color: #fff;
}

.page .page-section ul {
	display: flex;
	margin: 5px 0;
	list-style-type: none;
	flex-wrap: wrap;
}

.page .page-section li {
	color: #ccc;
	font-size: 1rem;
	margin: 0 5px;
}

.resultsTitle {
	color: white;
	font-family: 'Source Code', monospace;
	text-align: center;
	margin: 20px;
	margin-top: 10vh;
	font-size: 2rem;
}

/***********
 * Article *
 ***********/

article {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 80vw;
	min-height: 100vh;
	min-width: 330px;
	max-width: 700px;
	margin: 10vh auto;
	opacity: 1 !important;
	color: #fff;
}

article h2 {
	text-align: center;
	font-size: 2.5rem;
	margin: 30px;
}

article h3 {
	text-align: center;
	font-size: 1.8rem;
	margin-top: 40px;
	margin-bottom: 20px;
}

article p {
	margin: 2px 0;
	align-self: flex-start;
}

article iframe {
	border-radius: 15px;
	width: clamp(330px, 80vw, 700px);
	aspect-ratio: 16 / 9;
}

article ul {
	list-style-type: square;
	padding: 2px 20px;
}

article a {
	color: #fff;
	align-self: flex-start;
}

article a:hover {
	color: #ccc;
}

.image {
	width: clamp(330px, 80vw, 700px);
	margin: 10px 0;
}

.code {
	overflow-x: auto;
	width: clamp(330px, 80vw, 700px);
	margin: 10px 0;
	box-shadow: #00000077 2px 2px 2px;
}

.sources {
	margin: 20px 0;
	align-self: flex-start;
	display: flex;
	flex-direction: column;
}

.table-of-contents {
	align-self: flex-start;
}

/***********
 * Contact *
 ***********/

.contact {
	padding: 0 25px;
	display: flex;
	flex-direction: column;
	width: clamp(330px, 100%, 900px);
	align-items: center;
	color: #fff;
}

.contact input {
	padding: 0 10px;
	width: 100%;
	height: 6vh;
	border: 2px solid #fff;
	background: none;
	color: #fff;
	font-size: 1rem;
}

.contact input[type='submit'] {
	height: 4vh;
	text-align: center;
	transition: all 0.5s ease;
}

.contact input[type='submit']:hover {
	background-color: #1f1f1f;
}

.contact textarea {
	font-size: 1rem;
	padding: 10px;
	width: 100%;
	height: 20vh;
	resize: none;
	background: none;
	border: 2px solid #fff;
	color: #fff;
}

/************
 * Carousel *
 ************/

.carousel {
	user-select: none;
	color: #fff;
	align-items: center;
	overflow: hidden;
	border-radius: 15px;
	position: relative;
	display: flex;
	margin: 10px auto;
}

.arrow {
	z-index: 100;
	top: auto;
	width: 1.5em;
	height: 1.5em;
	position: absolute;
	opacity: 0;
	transition: transform 0.5s, opacity 0.2s ease-in;
}

.arrow.left {
	left: 10px;
	transform: translateX(15px);
}

.arrow.right {
	right: 10px;
	transform: translateX(-15px);
}

.carousel:hover .arrow,
.carousel:hover .dots {
	opacity: 1;
	transform: translateY(0px);
}

.arrow:hover {
	cursor: pointer;
	color: #bbb;
}

.dots {
	position: absolute;
	width: 100%;
	bottom: 0px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-items: center;
	opacity: 0;
	transform: translateY(5px);
	transition: transform 0.5s, opacity 0.2s ease-in;
}

.dots div {
	margin: auto;
}

.dot {
	color: #ffffff99;
	width: 60px;
	height: 60px;
}

.dot:hover {
	cursor: pointer;
	color: #eeeeeecc;
}

.dot.selected {
	color: #eee;
}

.image-slide {
	width: 80vw;
	min-width: 330px;
	max-width: 700px;
	display: flex;
	justify-content: center;
	background-color: #1f1f1ffd;
}

.image-slide img {
	height: 100%;
	max-height: 65vh;
	width: auto;
}

/***********
 * Project *
 ***********/

.projects-container {
	min-height: 92vh;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	color: #fff;
	padding: 20px 10vw;
}

.project-card {
	width: 330px;
	height: 465px;
	margin: 10px;
	border-radius: 15px;
	background: linear-gradient(#0f0f0f, #101010);
	box-shadow: 6px 6px 2px #00000024;
	transition: all 0.3s ease;
}

.project-card img {
	width: 330px;
	height: 200px;
	border-radius: 15px 15px 0px 0px;
}

.project-card:hover {
	z-index: 100;
	transform: translateY(-7px);
}

.project-content {
	padding: 1.2rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.project-content .category {
	text-align: center;
	align-self: flex-start;
	border-radius: 13px;
	font-size: 0.75rem;
	color: #fff;
	background: linear-gradient(#1f1f1f, #2c2c2c);
	padding: 0.25em 0.75em;
	margin-right: 0.75em;
}

.project-content a {
	font-size: 1.5rem;
	color: #fff;
	text-decoration: none;
}

.project-content a:hover {
	color: #eee;
}

.project-content p {
	padding: 0px !important;
}

.project-content p a {
	font-size: 1rem;
}

.project-footer {
	padding: 1rem;
	margin-top: auto;
}

.project-footer .lang {
	color: #ccc;
	margin-right: 0.5rem;
	font-size: 0.75rem;
}

.project-slide {
	width: 80vw;
	min-width: 330px;
	max-width: 900px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.project-info {
	position: absolute;
	transform: translateX(150%);
	width: 50%;
	height: 100%;
	background-color: #1f1f1ffd;
	transition: transform 0.4s ease-out;
}

.info-content {
	padding: 3rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.info-content a {
	font-size: 2rem;
	color: #fff;
	text-decoration: none;
}

.info-content p {
	padding: 0px !important;
}

.info-content p a {
	font-size: 1rem;
}

.info-content a:hover {
	color: #ddd;
}

.info-content .category {
	text-align: center;
	align-self: flex-start;
	border-radius: 13px;
	font-size: 0.75rem;
	color: #fff;
	background: linear-gradient(#2c2c2c, #3c3c3c);
	margin-right: 0.75em;
	padding: 0.25em 0.75em;
}

.project-info.project-content a {
	font-size: 3rem;
}

.languages {
	margin-top: 10px;
	font-size: 0.9rem;
	color: #ccc;
	margin-right: 0.75rem;
}

.project-slide img {
	width: 100%;
	transition: filter 0.4s ease-out;
}

.project-slide h1 {
	color: #fff;
}

.project-slide:hover .project-info {
	transform: translateX(calc(50% + 1px));
}

.project-slide:hover img {
	filter: blur(2px);
}

/************
 * Projects *
 ************/

.filter-container {
	display: flex;
	position: relative;
	margin: 20px;
}

.filter-container .search-container {
	width: 70vw !important;
	max-width: 500px !important;
	min-width: 280px !important;
	height: 40px;
	border: 2px #fff solid;
	background-color: #000;
	border-radius: 7px;
}

.filter-container .search-container .search-icon {
	width: 35px;
	height: 35px;
}

.filter-container .search-container .search {
	height: 40px;
	color: white;
	padding-left: 45px !important;
	padding-right: 35px !important;
	display: block !important;
	background: none !important;
	font-size: 1.2rem !important;
}

.filter-container .search-container:hover .search-icon,
.filter-container .search-container:valid .search-icon {
	color: #fff !important;
}

.filter {
	margin-left: 10px;
	position: absolute;
}

.filter-icon {
	color: #fff;
	width: 35px;
	height: 35px;
}

.dropdown {
	margin-top: 3px;
	width: 85px;
	z-index: 200;
	display: none;
}

.option {
	color: #fff;
	background-color: #000000f1;
	padding: 4px;
	transition: all 0.2s ease;
}

.option-selected {
	color: #fff;
	background-color: #222222f1;
	padding: 4px;
	transition: all 0.2s ease;
}

.option:hover {
	background-color: #1c1c1cf1 !important;
	cursor: pointer;
}

.filter:hover .dropdown {
	display: block;
}

/**********
 * To Top *
 **********/

.to-top {
	color: #fff;
	font-size: 15px;
	padding: 8px;
	position: fixed;
	bottom: 5px;
	right: 20px;
	border: 3px #00000000 solid;
	border-radius: 15%;
	opacity: 0;
	transition: border-color 0.3s ease, opacity 0.5s ease;
	cursor: pointer;
	z-index: 100;
}

.to-top:hover {
	border-color: #fff;
}

/**********
 * Footer *
 **********/

footer {
	height: 30vh;
	width: 100vw;
	background-color: #111111;
	display: flex;
}

.footer-section {
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.footer-section h4 {
	text-align: center;
	color: #fff;
	font-size: 1.5rem;
}

.footer-section h5 {
	position: absolute;
	left: auto;
	bottom: 0;
	color: #aaa;
	padding-bottom: 5px;
	font-size: 0.7rem;
}

.social-links {
	margin: 15px;
	display: flex;
}

.social-links a {
	text-decoration: none;
	font-size: 2.5rem;
	margin: 0 10px;
	color: #fff;
	transition: 0.2s;
}

.social-links a:hover {
	color: #ccc;
	transform: scale(105%);
}

/*********************
 * Responsive Layout *
 *********************/

.featured-cards {
	display: none !important;
	flex-direction: column;
	align-items: center;
}

.featured-cards h3 {
	color: #fff;
	font-size: 2rem;
	margin: 20px;
}

.featured-cards h3::after {
	content: ':';
	animation: blinking 0.8s steps(2, start) infinite;
}

/*************
 * No Result *
 *************/

.noresult {
	display: flex;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #fff;
	font-size: 2.5rem;
}

.noresult a {
	color: #fff;
	font-size: 1.5rem;
}

@media screen and (max-width: 950px) {
	.search-container {
		display: none;
	}

	.filter-container .search-container {
		display: block;
	}

	.nav-links {
		z-index: 1;
		position: absolute;
		right: 0px;
		height: 100vh;
		top: 0;
		background-color: #000000bb;
		align-items: center;
		flex-direction: column;
		width: 0%;
		transition: width 0.5s ease-in-out;
		overflow: hidden;
	}
	.nav-links li {
		transition: opacity 0.25s ease-in-out;
		opacity: 0;
	}

	.nav-active {
		width: 50%;
	}

	.nav-active li {
		opacity: 1;
	}

	.burger {
		z-index: 100;
		margin-right: 20px;
		display: block;
		float: right;
	}

	.home-carousel {
		display: none !important;
	}

	.featured-cards {
		display: flex !important;
	}
}

@keyframes flashing {
	50% {
		opacity: 0;
	}
}

@keyframes blinking {
	to {
		visibility: hidden;
	}
}

@keyframes typing {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}

/* Snake */

.snake {
	display: grid;
	place-items: center;
	width: 100vw;
	height: 100vh;
}

.snake canvas {
	width: 400px;
	height: 425px;
}
